<template>
<div class="ConclusionTable">
  
  <a-table
    class="ConclusionTable__table"
    :dataSource="samplesInit.samples"
    :columns="columns"
    :pagination="false"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'number'">
        {{ `Sample ${record.number}` }}
      </template>
    </template>
  </a-table>

</div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "ConclusionTable",
  data() {
    return {
      columns: [
        {
          key: 'number',
          dataIndex: 'number',
          title: 'Sample No',
        },
        {
          key: 'time',
          dataIndex: 'time',
          title: 'Time (min)',
          align: 'right',
        },
        {
          key: 'hydrogen',
          dataIndex: 'hydrogen',
          title: 'Hydrogen (ppm)',
          align: 'right',
        },
        {
          key: 'methane',
          dataIndex: 'methane',
          title: 'Methane (ppm)',
          align: 'right',
        },
        {
          key: 'carbon_dioxide',
          dataIndex: 'carbon_dioxide',
          title: 'Carbon Dioxide (cf)',
          align: 'right',
        },
        {
          key: 'bloating',
          dataIndex: 'bloating',
          title: 'Bloating (0-10)',
          align: 'right',
        },
        {
          key: 'abdominal_pain',
          dataIndex: 'abdominal_pain',
          title: 'Abdominal Pain (0-10)',
          align: 'right',
        },
        {
          key: 'nausea',
          dataIndex: 'nausea',
          title: 'Nausea (0-10)',
          align: 'right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      samplesInit: 'CmsSampleStore/getInit',
    }),
  },
}
</script>

<style lang="scss" scoped>
.ConclusionTable {
  
  &__table {
    &:deep(.ant-table-cell) {
      line-height: 18px;
    }
  }
  
}
</style>