<template>
  <div class="ConclusionForm">

    <a-form
        ref="form"
        :model="formState"
        :rules="formRules"
        validateTrigger="onSubmit"
        :hideRequiredMark="true"
        @finish="onSubmit"
    >
      <div v-if="formFirstScreen">
        <!-- Referring Physician -->
        <a-form-item
            class="ConclusionForm__field"
            name="form_referring_physician"
        >
          <span class="ResultLayout__label">
            Referring Physician
          </span>
          <a-input
              v-model:value="form_referring_physician"
              :placeholder="formSetup['referring_physician'].placeholder"
              :disabled="loading"
              @change="onChange"
          />
        </a-form-item>

        <!-- Patient Number -->
        <a-form-item
            class="ConclusionForm__field"
            name="form_patient_number"
        >
          <span class="ResultLayout__label">
            Patient Number
          </span>
          <a-input
              v-model:value="form_patient_number"
              :placeholder="formSetup['patient_number'].placeholder"
              :disabled="loading"
              @change="onChange"
          />
        </a-form-item>

        <!-- Select report template -->
        <a-form-item
            class="ConclusionForm__field"
            name="form_report_template_id"
        >
          <span class="ResultLayout__label">
            Report Template
          </span>
          <a-select
              v-model:value="form_report_template_id"
              :placeholder="formSetup['report_template_id'].placeholder"
              :options="reportTemplates"
              :fieldNames="{ label: 'title', value: 'id' }"
              :disabled="loading"
              @change="onChange"
          ></a-select>
        </a-form-item>

        <!-- Select conclusion template -->
        <a-form-item
            class="ConclusionForm__field"
            name="form_conclusion_template_id"
        >
          <span class="ResultLayout__label">
            Conclusion Template
          </span>
          <a-select
              v-model:value="form_conclusion_template_id"
              :placeholder="formSetup['conclusion_template_id'].placeholder"
              :options="conclusionTemplates"
              :fieldNames="{ label: 'title', value: 'id' }"
              :disabled="loading"
              @change="onConclusionTemplate"
          ></a-select>
        </a-form-item>
        <a-button
            type="green"
            class="ConclusionForm__button ConclusionForm__field"
            style="width: 100%"
            :loading="loading"
            @click="onNext"
        >
          Next
        </a-button>
      </div>
      <div v-else>
        <!-- Select results -->
        <a-form-item
            class="ConclusionForm__field"
            name="form_result_united_kingdom"
        >
            <span v-if="requestInit.test_item.title === 'Lactulose' || requestInit.test_item.title === 'Glucose'"
                  class="ResultLayout__label">
              United Kingdom SIBO
            </span>
          <span v-else
                class="ResultLayout__label">
                {{ this.requestInit.test_item.title }}
              </span>
          <a-select
              v-model:value="form_result_united_kingdom"
              style=""
              :options="options"
              @change="onChange"
          >
          </a-select>
        </a-form-item>

        <a-form-item
            v-if="requestInit.test_item.title === 'Lactulose' || requestInit.test_item.title === 'Glucose'"
            class="ConclusionForm__field"
            name="form_result_north_american"
        >
            <span class="ResultLayout__label">
              North American SIBO
            </span>
          <a-select
              v-model:value="form_result_north_american"
              style=""
              :options="options"
              @change="onChange"
          >
          </a-select>
        </a-form-item>

        <a-form-item
            v-if="requestInit.test_item.title === 'Lactulose' || requestInit.test_item.title === 'Glucose'"
            class="ConclusionForm__field"
            name="form_result_methane"
        >
            <span class="ResultLayout__label">
              Methane
            </span>
          <a-select
              v-model:value="form_result_methane"
              style=""
              :options="options"
              @change="onChange"
          >
          </a-select>
        </a-form-item>

        <!-- Enter conclusion or select template -->
        <a-form-item
            class="ConclusionForm__field"
            name="form_conclusion"
        >
          <span class="ResultLayout__label">
              Conclusion
          </span>
          <a-textarea
              v-model:value="form_conclusion"
              :placeholder="formSetup['conclusion'].placeholder"
              :disabled="loading"
              @change="onChange"
              :rows="12"
          />
        </a-form-item>
      </div>

      <div
          v-if="!formFirstScreen"
          class="ConclusionForm__buttons"
      >
        <a-button
            type="green-outline"
            class="ConclusionForm__button"
            :loading="loading"
            @click="onBack"
        >
          Back
        </a-button>
        <a-button
            type="green"
            class="ConclusionForm__button"
            :loading="loading"
            @click="onContinue"
        >
          Save & Continue
        </a-button>
      </div>
    </a-form>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex"
import {mapFields} from "@/helpers/form.helper"
import {ApiResult} from "@/models";
import {Request} from '@/models/api_v1'
import {CmsConclusionService} from "@/services/api_v1/cms"
import {toast} from "@/helpers/error.helper"
import _ from "lodash"

let serverErr, formSetup = {
  referring_physician: {
    placeholder: '',
    rules: [
      {max: 255, message: ''},
    ],
  },
  patient_number: {
    placeholder: '',
    rules: [
      {required: true, message: ''},
      {max: 255, message: ''},
    ],
  },
  report_template_id: {
    placeholder: '',
    rules: [
      {required: true, message: ''},
    ],
  },
  conclusion_template_id: {
    placeholder: '',
    rules: [
      {required: true, message: ''},
    ],
  },
  result_united_kingdom: {
    rules: [
      {required: true, message: ''},
    ],
  },
  result_north_american: {
    rules: [
      {required: true, message: ''},
    ],
  },
  result_methane: {
    rules: [
      {required: true, message: ''},
    ],
  },
  conclusion: {
    placeholder: '',
    rules: [
      {required: true, message: ''},
      {max: 1000, message: ''},
    ],
  },
}

export default {
  name: "ConclusionForm",
  data() {
    return {
      loading: false,
      formSetup,
      formFirstScreen: true,
      options: [
        {
          value: 'Positive',
          label: 'Positive',
        },
        {
          value: 'Negative',
          label: 'Negative',
        },
        {
          value: 'Inconclusive',
          label: 'Inconclusive',
        },
      ],
    }
  },
  computed: {
    ...mapFields({
      formSetup,
      props: ['fields', 'formState', 'formRules'],
      getter: (vm, field) => _.get(vm.requestInit, field),
      setter: (vm, field, value) => vm.setRequestInitField({field, value}),
    }),
    ...mapGetters({
      requestIsInit: 'CmsRequestStore/getIsInit',
      requestInit: 'CmsRequestStore/getInit',
      init: 'CmsInitialStore/getInit',
    }),
    reportTemplates() {
      return this.init.report_templates
    },
    conclusionTemplates() {
      let test_item_id = this.requestInit.test_item.id
      return this.init.conclusion_templates?.filter((i) => i.test_item_id === test_item_id)
    },
  },
  methods: {
    ...mapMutations({
      setRequestInit: 'CmsRequestStore/setItemInit',
      setRequestInitField: 'CmsRequestStore/setItemInitField',
      updateRequest: 'CmsRequestStore/updateItem',
    }),
    onChange() {
      this.$refs.form.clearValidate()
      serverErr = null
    },
    onConclusionTemplate(value, option) {
      this.form_conclusion = option.content.text
      this.onChange()
    },
    async onSubmit() {
      let request = this.requestInit.toApi()
      await CmsConclusionService.updateById(request.barcode, request)
          .then((response) => {
            let result = new ApiResult(response),
                item = Request.fromApi(result.data.data)
            if (result.status === 200) {
              toast({type: 'success', msg: 'Save successful'})
              this.setRequestInit(item)
              if (this.requestIsInit) {
                this.updateRequest(item)
              }
            }
          })
    },
    async onContinue() {
      await this.$refs.form.validate()
          .then(async () => {
            await this.onSubmit()
                .then(() => {
                  this.$router.push({name: 'cms.report', params: {barcode: this.requestInit.barcode}})
                })
          })
          .catch((error) => {
          })
    },
    async onNext() {
      await this.$refs.form.validate()
          .then(async () => {
            this.formFirstScreen = false;
            this.emitter.emit("hide-sidebar", true);
          })
          .catch((error) => {
          })
    },
    onBack() {
      this.formFirstScreen = true;
      this.emitter.emit("hide-sidebar", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.ConclusionForm {
  margin-top: 5px;

  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 15px;
  }

  &__button {
    flex: 1;
  }

  &__field {
    margin-bottom: 15px;

    &:deep(.ant-form-item-explain) {
      display: none;
    }
  }
}

.ResultLayout {

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $secondary45;
  }

}
</style>