<template>
<AuthLayout
  :breadcrumbs="breadcrumbs"
  :sidebar="true"
>
<template #title>

  <StepsLayout
    active="conclusion"
  />

</template>
<template #content>
  <div
    class="ConclusionPage"
    :style="`height: calc(100vh - ${sum([
      48, // HeaderLayout
      104, // SubHeaderLayout
      24, // AuthLayout__wrap
    ])}px)`"
  >

    <div class="ConclusionPage__title">
      Gas Production and Symptom Results
    </div>
    <template v-if="plot">
      <img
        class="ConclusionPage__plot"
        :src="plot"
      />
    </template>
    <template v-else>
      <a-skeleton-image
        class="ConclusionPage__plot-skeleton"
      />
    </template>

    <template v-if="samplesInit.patient_comments">
      <div class="ConclusionPage__title">
        Patient's comment
      </div>
      <div class="ConclusionPage__comment">
        {{ samplesInit.patient_comments }}
      </div>
    </template>

    <div class="ConclusionPage__title">
      Symptoms Sheet
    </div>
    <ConclusionTable />

  </div>
</template>
<template #sidebar>

  <ConclusionForm
    ref="ConclusionForm"
  />

</template>
</AuthLayout>
</template>

<script>
import AuthLayout from "@/components/cms/auth.layout/AuthLayout"
import StepsLayout from "@/components/cms/auth.layout/StepsLayout"
import ConclusionForm from "@/components/cms/conclusion/ConclusionForm"
import ConclusionTable from "@/components/cms/conclusion/ConclusionTable"
import {CmsChartService, CmsRequestsService} from "@/services/api_v1/cms"
import { mapActions, mapGetters, mapMutations } from "vuex"
import _ from "lodash"
import {ApiResult} from "@/models";
import {Request} from "@/models/api_v1";
import {toast} from "@/helpers/error.helper";

export default {
  name: "ConclusionPage",
  components: {
    ConclusionTable,
    ConclusionForm,
    AuthLayout,
    StepsLayout,
  },
  data() {
    return {
      breadcrumbs: [
        { name: `Requests`, route: { name: `cms.requests` } },
        { name: this.$route.params.barcode },
      ],
    }
  },
  computed: {
    ...mapGetters({
      requestInit: 'CmsRequestStore/getInit',
      samplesInit: 'CmsSampleStore/getInit',
      getPlot: 'CmsRequestStore/getPlot',
    }),
    barcode() {
      return this.$route.params.barcode
    },
    plot() {
      return this.getPlot(this.barcode) || ''
    },
  },
  methods: {
    sum(v) { return _.sum(v) }, // lodash sum
    ...mapActions({
      fetchSamples: 'CmsSampleStore/fetchInit',
    }),
    ...mapMutations({
      setPlot: 'CmsRequestStore/setPlot',
      setRequestInit: 'CmsRequestStore/setItemInit',
      setRequestInitField: 'CmsRequestStore/setItemInitField',
      updateRequest: 'CmsRequestStore/updateItem',
    }),
  },
  async mounted() {
    if (this.samplesInit.barcode !== this.barcode) {
      await this.fetchSamples(this.barcode)
    }

    await CmsChartService.getImg(this.barcode)
      .then((response) => {
        if (response.status === 200) {
          this.setPlot({ barcode: this.barcode, value: response.data })
        }
      })
  }
}
</script>

<style lang="scss" scoped>
.ConclusionPage {
  width: calc(100% - 320px - 24px); // scrollbar-width: thin fix
  background-color: white;
  padding: 24px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.15) transparent;

  &::-webkit-scrollbar {
      width: 13px;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 5px 15px 15px 5px;
      background-color: rgba(0, 0, 0, 0.15);
      border-right: 8px transparent solid;
      background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
      margin: 10px;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 24px;
  }

  &__comment {
    padding-bottom: 24px;
  }

  &__plot-skeleton {
    width: 100%;
    margin-bottom: 24px;
    aspect-ratio: 5 / 2; // https://caniuse.com/mdn-css_properties_aspect-ratio

    &:deep(.ant-skeleton-image) {
      width: 100%;
      height: 100%;
    }
  }

  &__plot {
    width: 100%;
    margin-bottom: 24px;
  }
}

</style>
